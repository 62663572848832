import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookieUtil from "@/utils/cookieUtils";
import { B2C_C_LANG } from "@/app/csr-src/data/consts/lang";
import { IWindow } from "@/types/global";

import en_US from "./locales/en_US.json";
import zh_CN from "./locales/zh_CN.json";

const lang = cookieUtil.getCookie(B2C_C_LANG) || "en_US";

i18n
  .use(initReactI18next) // pass i18n down to react-i18next
  .init({
    resources: {
      en_US: {
        translation: en_US,
      },
      zh_CN: {
        // 异步加载 todo
        translation: zh_CN,
      },
      // 添加更多语言
    },
    debug: false,
    lng: lang, // 默认语言
    fallbackLng: "en_US", // 回退语言
    interpolation: {
      escapeValue: false, // React 已经安全处理了
    },
    returnNull: false, // 在 i18next 中，returnNull 是一个配置选项，用于控制当翻译键缺失时的返回值。具体来说，当设置了 returnNull: true 时，如果请求的键在翻译资源中不存在，t 函数将返回 null 而不是默认的键名或空字符串。这在某些情况下可以帮助你更好地处理缺失的翻译。
    parseMissingKeyHandler: (key) => {
      // parseMissingKeyHandler 是 i18next 中的一个配置选项，用于处理缺失的键（即翻译文件中未定义的键）。当你调用 t 函数翻译一个字符串时，如果该键在翻译资源中不存在，parseMissingKeyHandler 可以被用来定义如何处理这种情况
      return `fe.${key}`;
    },
  });

// ssr中执行会报错，暂时先不挂载
// 存储 i18n 实例为全局变量
// (window as unknown as IWindow).appI18n = i18n;

export default i18n;
