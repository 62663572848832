"use client";
import "./assets/css/global.css";
// import { App } from "./App";
import i18n from "@/app/csr-src/i18n";
import { I18nextProvider } from "react-i18next";
import dynamic from "next/dynamic";

const App = dynamic(() => import("./App"), { ssr: false });

export default function CSR_SRC() {
  return (
    <>
      <div id="root">
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </div>
      <div id="loading-mask"></div>
    </>
  );
}
